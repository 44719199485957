<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">About BeMagnified</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start About Area  -->
    <div class="about-area rn-section-gap bg_color--1">
      <div class="about-wrapper">
        <HomeIntro>
          <img
            slot="image"
            src="../assets/images/about/people.png"
            alt="About Images"
          />

          <div slot="main-content" class="section-title">
            <h3 class="heading-title mb-7">
              Who We Are
              <img
                class="inline-img"
                src="../assets/images/logo/symbol-yellow.png"
              />
            </h3>
            <p class="description">
              At BeMagnified Media and Marketing, we believe every brand has
              untapped potential. Founded by a team of visionaries, strategists,
              and innovators, we are driven by a single mission: to magnify your
              business's potential and help you thrive in the ever-evolving
              digital landscape.
            </p>
            <p class="description">
              We are more than just a media and marketing agency; we are the
              magnifying glass that focuses your brand's strengths and projects
              them to the world.
            </p>
          </div>
        </HomeIntro>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area bg_color--1 mb--100">
      <v-container>
        <CounterOne />
      </v-container>
    </div>
    <!-- End Counterup Area  -->

    <YourVisionOurMission>
      <div slot="main-content" class="pr--70 pr_sm--0 pr_md--0">
        <div class="section-title">
          <h2>Your Vision is Our Mission.</h2>
        </div>
        <p class="description">
          Our mission is simple yet ambitious: to elevate your brand and
          maximize your success. Through cutting-edge strategies, innovative
          solutions, and a deep understanding of digital trends, we tailor our
          services to meet your unique needs and goals.
        </p>
        <div class="action-wrapper">
          <router-link
            to="/services"
            class="rn-button-style--2 btn_solid mt--30"
          >
            Our Services
          </router-link>
          <router-link
            to="/portfolio"
            class="rn-button-style--2 btn_solid mt--30 ml--20"
          >
            Our Portfolio 
          </router-link>
        </div>
      </div>

      <img
        slot="image"
        class="about-img w-100"
        src="../assets/images/about/mission.png"
        alt="About Image"
      />
    </YourVisionOurMission>

    <UnlockYourPotential />

    <!-- Start Testimonial Area  -->
    <!-- <div class="rn-testimonial-area rn-section-gap bg_color--1">
      <v-container>
        <Testimonial />
      </v-container>
    </div> -->
    <!-- Start Testimonial Area  -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header/Header";
import HomeIntro from "../components/about/HomeIntro";
import CounterOne from "../components/counter/CounterOne";
import YourVisionOurMission from "../components/about/YourVisionOurMission";
import Testimonial from "../components/testimonial/Testimonial";
import UnlockYourPotential from "../components/callto-action/UnlockYourPotential.vue"
import Footer from "../components/footer/Footer";

export default {
  components: {
    Header,
    HomeIntro,
    CounterOne,
    YourVisionOurMission,
    UnlockYourPotential,
    Testimonial,
    Footer
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "About",
          to: "",
          disabled: true
        }
      ]
    };
  }
};
</script>

<style scoped>
.inline-img {
  display: inline;
  width: 40px;
  margin-left: 10px;
}
</style>
